@charset "utf-8";



// Our variables
$base-font-family: 		Helvetica, Arial, sans-serif;
$base-font-size:   		18px;
$small-font-size:  		$base-font-size * 0.875;
$base-line-height: 		1.5;

$spacing-unit:     		30px;

$text-color:       		#111;
$text-color-dark:  		#fdfdfd;
$background-color: 		#fdfdfd;
$background-color-dark:	#030303;
$brand-color:      		#20CCBC;
$pink-color:       		#FF00A1;
$warning-color:         #FFEB00;
$warning-color-dark:    #B0A100;

$grey-color:       		#828282;
$grey-color-light: 		lighten($grey-color, 40%);
$grey-color-dark:  		darken($grey-color, 25%);

$on-palm:          		600px;
$on-laptop:        		800px;



// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
