/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, blockquote {
    margin: $spacing-unit 0;
}


/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    -webkit-text-size-adjust: 100%;

    color: $text-color;
    background-color: $background-color;
    
    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
        background-color: $background-color-dark;
    }
}

@media (prefers-color-scheme: light) {
    body {

    }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
    color: $grey-color;
    margin-bottom: $spacing-unit;
    margin-left: $spacing-unit/2;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 20%);
    }

    &:hover {
        text-decoration: underline;
    }
}

a:hover {
    color: $text-color;

    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
    }
}


/**
 * Blockquotes
 */
blockquote {

    border-left: 4px solid $grey-color-light;
    
    @media (prefers-color-scheme: dark) {
        border-left: 4px solid $grey-color-dark;
    }

    color: $grey-color;
    padding-left: $spacing-unit / 2;
    font-size: 1.08em;
    letter-spacing: -1px;
    font-style: italic;
    margin-top: $spacing-unit / 2;

    > :last-child {
        margin-bottom: 0;
    }

    p em {
        font-style: normal;
    }
}


/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;

    @media(prefers-color-scheme: dark) {
        border: 1px solid $grey-color-dark;
        background-color: #333;
    }
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(800px - (#{$spacing-unit} * 2));
    max-width:         calc(800px - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(800px - (#{$spacing-unit}));
        max-width:         calc(800px - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
}

.greyout {
    > svg {
        path {
            fill: $grey-color;
        }
    }
}

hr {
    border: 0.5px solid $grey-color-dark;
    color: $grey-color-dark;
    background-color: $grey-color-dark;
    margin: 0 3em $spacing-unit 1em;
}
